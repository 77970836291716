import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
import ThoughtsOutput from "../output/thoughts-output";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Thoughts`}</h1>
    <p>{`Here I document concise posts about web development or programming that I am learning or have recently discovered. Many times these entries are very short, as I am not a prolific blogger. However, hopefully something I list here will be helpful to others.`}</p>
    <ThoughtsOutput mdxType="ThoughtsOutput" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      